import { Box, Typography, Grid, Card, Avatar, Container } from "@mui/material";
import MovieIcon from '@mui/icons-material/Movie';
import UsbIcon from '@mui/icons-material/Usb';
import ShareIcon from '@mui/icons-material/Share';

const Services = () => {
  return (
    <Container
      id="services"
      sx={{
        backgroundColor: 'white',
        padding: '20px 0', // Reduced vertical padding
        color: 'black',
        margin: '0 auto',
        maxWidth: 'md', // Changed to 'md' for a smaller max width
      }}
    >
      <Typography variant="h4" align="center" sx={{ marginTop: 2, marginBottom: 2 }}>
        Our Services
      </Typography>
      <Grid container spacing={3} justifyContent="center"> {/* Reduced spacing between Grid items */}
        <Grid item xs={12} md={4}>
          <Card sx={{ padding: '1.5vw', textAlign: "center" }}> {/* Reduced card padding */}
            <Avatar sx={{ margin: '0 auto', width: 80, height: 80 }} className="avatar-background">
              <MovieIcon sx={{ fontSize: 40 }} />
            </Avatar>
            <Typography variant="h6" sx={{ marginTop: 2 }}>Service One</Typography>
            <Typography>
              We provide high-quality service one to help your business achieve its goals.
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ padding: '1.5vw', textAlign: "center" }}>
            <Avatar sx={{ margin: '0 auto', width: 80, height: 80 }} className="avatar-background">
              <UsbIcon sx={{ fontSize: 40 }} />
            </Avatar>
            <Typography variant="h6" sx={{ marginTop: 2 }}>Service Two</Typography>
            <Typography>
              Our team excels at delivering service two to ensure your satisfaction.
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ padding: '1.5vw', textAlign: "center" }}>
            <Avatar sx={{ margin: '0 auto', width: 80, height: 80 }} className="avatar-background">
              <ShareIcon sx={{ fontSize: 40 }} />
            </Avatar>
            <Typography variant="h6" sx={{ marginTop: 2 }}>Service Three</Typography>
            <Typography>
              Service three is our specialty, designed to meet your specific needs.
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Services;
