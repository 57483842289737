import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, TextField, Button, MenuItem, Card, CardContent } from "@mui/material";
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    city: '',
    message: '',
  });

  const submitContactUrl = process.env.REACT_APP_SUBMIT_CONTACT_URL;
  const getCitiesUrl = process.env.REACT_APP_GET_CITIES_URL;
  const [cities, setCities] = useState([]);

  // Fetch cities on component mount
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get(getCitiesUrl);
        setCities(response.data.cities); // Access the 'cities' array from the response
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchCities();
  }, []);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(submitContactUrl, formData);
      console.log('Submission successful:', response.data);
      alert('Message sent successfully!');
    } catch (error) {
      console.error('There was an error sending the message:', error);
      
      // Extract the error message and show it in the alert
      const errorMessage = error.response?.data?.errors 
          ? JSON.stringify(error.response.data.errors) 
          : 'Failed to send message.';

      alert(`Failed to send message: ${errorMessage}`);
    }
  };

  return (
    <Box 
      id="contact" 
      sx={{ 
        backgroundColor: 'black', 
        color: 'white', // Change text color for contrast
        padding: '20px 0', 
        margin: 0,
        display: 'flex', // Use flexbox to center content
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically if needed
        overflow: 'hidden', // Prevent overflow
      }}
    >
      <Card 
        sx={{ 
          backgroundColor: 'white', 
          padding: '2vw', 
          borderRadius: '16px', // Rounded corners
          boxShadow: 3, // Add some shadow for card effect
          maxWidth: 'sm', // Use maxWidth for responsiveness
          width: '100%', // Ensure full width on smaller screens
        }}
      >
        <CardContent>
          <Typography variant="h4" align="center" sx={{ marginTop: 2, marginBottom: 2 }}>
            Contact Us
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Box>
                  <TextField 
                    fullWidth 
                    label="Your Name" 
                    name="name" 
                    margin="normal"
                    value={formData.name} 
                    onChange={handleChange}
                  />
                  <TextField 
                    fullWidth 
                    label="Your Phone" 
                    name="phone" 
                    margin="normal"
                    value={formData.phone} 
                    onChange={handleChange}
                  />
                  <TextField 
                    fullWidth 
                    label="Your Email" 
                    name="email" 
                    margin="normal"
                    value={formData.email} 
                    onChange={handleChange}
                  />
                  <TextField
                    select
                    fullWidth
                    label="Your City"
                    name="city"
                    margin="normal"
                    value={formData.city}
                    onChange={handleChange}
                  >
                    {cities.map((city, index) => (
                      <MenuItem key={index} value={city}>
                        {city}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField 
                    fullWidth 
                    label="Your Message" 
                    name="message" 
                    margin="normal" 
                    multiline 
                    rows={4}
                    value={formData.message} 
                    onChange={handleChange}
                  />
                  <Button variant="contained" color="primary" size="large" fullWidth type="submit">
                    Send Message
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Contact;
