import React from "react";
import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box mt={0} py={3} bgcolor="black" color="white" textAlign="center">
      <Typography>© {new Date().getFullYear()} Heirloom Media Preservation. All rights reserved.</Typography>
    </Box>
  );
};

export default Footer;
