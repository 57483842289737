import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Box,
  Menu,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import '../App.css';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null); // State for the menu

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget); // Set anchor element for the menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the menu
  };


  return (
    <AppBar
      position="fixed"
      sx={{
        background: 'linear-gradient(144deg, #000000, #9c5957)',
        backgroundSize: '400% 400%',
        animation: 'gradient 8s ease infinite',
        overflowX: 'hidden', // Prevent horizontal scrollbar
            width: '100%', // Ensure the AppBar takes the full width of the viewport
    margin: 0, // Remove any margin that might affect the width
    padding: 0, // Remove any padding to ensure no extra space
      }}
    >
      <Toolbar sx={{ padding: 0, height: "100%" }}> {/* Remove all padding */}
        <Typography 
          variant="h6" 
          className="gradient-text" 
          sx={{ 
            flexGrow: 1,
            marginLeft: 1, // Add some margin to the left
          }}
        >
          Heirloom Media Preservation
        </Typography>

        {/* Hamburger Menu Icon */}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuOpen} // Open the drawer
          sx={{ display: { xs: 'block', md: 'none' }, marginRight: 1 }} // Show on mobile only
        >
          <MenuIcon />
        </IconButton>

        {/* Desktop Buttons */}
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Button className="gradient-text" href="#services" sx={{ marginRight: 5 }}>
            Services
          </Button>
          <Button className="gradient-text" href="#pricing" sx={{ marginRight: 5 }}>
            Pricing
          </Button>
          <Button className="gradient-text" href="#contact" sx={{ marginRight: 5 }}>
            Contact
          </Button>
        </Box>

        {/* Drawer */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)} // Menu open state
          onClose={handleMenuClose} // Close the menu
        >
          <MenuItem onClick={handleMenuClose} component="a" href="#services" sx={{ margin:1 }}>Services</MenuItem>
          <MenuItem onClick={handleMenuClose} component="a" href="#pricing" sx={{ margin:1 }}>Pricing</MenuItem>
          <MenuItem onClick={handleMenuClose} component="a" href="#contact" sx={{ margin:1 }}>Contact</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
