import React from "react";
import { Box, Typography, Button, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  hero: {
    height: "100vh",
    backgroundImage: `url('/tapes_2.svg')`,
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    textAlign: "center",
    padding: "20px 0px", // This is fine for responsive padding
    width: "100%", // Ensure it takes full width
  },
});

const HeroSection = () => {
  const classes = useStyles();
  
  return (
    <Box className={classes.hero} id="hero">
      <Container sx={{ maxWidth: 'lg', padding: '0 0px' }}> {/* Set maxWidth to control overflow */}
        <Typography variant="h2" sx={{ fontWeight: "bold", fontFamily: "Fredericka the Great" }}>
          Heirloom Media Preservation
        </Typography>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: "normal", fontFamily: "Fredericka the Great" }}>
          Digitize Your Family Tapes Today!
        </Typography>
        <Button variant="contained" size="large" href="#services" className="shiny-button" sx={{ color: "black" }}>
          Learn More
        </Button>
      </Container>
    </Box>
  );
};

export default HeroSection;
