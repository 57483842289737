import React from "react";
import { Box, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  hero: {
    height: "80vh", // Reduced height to decrease overall white space
    backgroundImage: `url('/pricing.svg')`, // Update this to your actual pricing SVG path
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    textAlign: "center",
    padding: "0 0px", // Reduced horizontal padding
    marginBottom: "1.5vw", // Reduced bottom margin
  },
});

const Pricing = () => {
  const classes = useStyles();
  
  return (
    <Box className={classes.hero} id="pricing">
      <Container>
        {/* Any additional content can go here, e.g., buttons, links, etc. */}
      </Container>
    </Box>
  );
};

export default Pricing;
